import React, { useEffect, useState } from 'react';  
import  PageTitle  from '../components/PageTitle';
import { Link, useParams, useNavigate  } from 'react-router-dom'; 
import { AuthGetData, AuthPostData } from '../hook'; 
import Loader from '../components/Loader';
import ReactPaginate from 'react-paginate';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { Account } from '../types';

const Accounts = () => { 
  const [accounts, setAccounts] = useState<Account[]>([]) ;
  const [loading, setLoading] = useState(false) ;
  const [processing, setProcessing] = useState(false) ; 
  let { page } = useParams();
  const navigate = useNavigate();
  let cPage = page ? parseInt(page) : 1;
  const [paginationInfo, setPaginationInfo] = useState({pageCount: 1,})
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/accounts', {page:cPage}).then((response) => {
    
      if(response.success ){ 
        setAccounts(response.results.data);
        setPaginationInfo({pageCount: response.results.last_page == 1 ? 0 :  response.results.last_page});
      } 
      else{
         
      }
      setLoading(false);
    });  
     
  },[cPage]);
  const handlePageClick = (event:any) => {
    let selected_page = event.selected + 1;
    navigate('/accounts/page/'+selected_page);  
  };
  const deleteAccounts = (ids:Number[], acc_numbers:String[]) => {
    const confirmBox = window.confirm(
      `Do you really want to delete Account(s): ${acc_numbers.join(',')}?`
    )
    if (confirmBox === true) {
      setProcessing(true);
      AuthPostData('/delete-accounts', {ids:ids}).then((response) => {
        console.log(response);
        if(response.success ){ 
          if(response.results.deleted.length > 0){
            toast.success('Deleted Account ID(s): '+response.results.deleted.join(','), {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            setAccounts(
              accounts.filter(account =>  !response.results.deleted.includes(account.id) )
            );
          }
          if(response.results.error.length > 0){
            toast.error('Can not delete Account ID(s): '+response.results.error.join(','), {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } 
        else{
          toast.error(response.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        
        setProcessing(false);
      });  
    }
  };
  return ( 
     <div>
      <PageTitle title="Cash Accounts" />
      <ToastContainer />  

      <div className="py-2 mb-3"><Link to="/add-account" className="btn btn-primary">Add Account</Link> </div>

      <div className={"accounts-list list-data" + (processing ? " loading" : "")}> 
        <div className="table-container">
          <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
            <tr>
              <th>ID</th>
              <th>Account Number</th> 
              <th>Account Description</th> 
              <th className="action-col"></th>
                
            </tr>
            </thead>
            <tbody>
              {loading ? <tr><td colSpan={4}><Loader /></td></tr> : ( 
              accounts.map((account: any, index) => (
              <tr key={index}>
                <td>{account.id}</td>
                <td>{account.account_number}</td>
                <td>{account.account_description}</td> 
                
                <td>
                <Link to={"/edit-account?id="+account.id} className="action-btn w-auto" style={{minWidth:'1px'}}>Edit</Link>   
                <button className="action-btn w-auto bg-red" style={{minWidth:'1px'}} onClick={ () => deleteAccounts([account.id],[account.account_number])}>Delete</button>
                </td>
              </tr> 
              ))
              )
              }
              
            
            </tbody>
            
          </table>
        </div>
        <div className="pagi text-center ">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick} 
            initialPage={cPage-1} 
            disableInitialCallback = {true}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={paginationInfo.pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName='page-item'
            className="pagination pagination-sm d-inline-flex"
            pageLinkClassName="page-link"
            previousClassName="page-link"
            nextClassName="page-link"
          />     
        </div>
      </div>


    </div>

 
  );
}

export default Accounts;
