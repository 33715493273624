import React, {  useEffect, useState } from 'react'; 
import $ from 'jquery';   
import  PageTitle  from '../components/PageTitle';
import { Link } from 'react-router-dom'; 
import { AuthGetData, AuthPostData, UrlParams } from '../hook'; 
import Loader from '../components/Loader';
import { NumericFormat } from 'react-number-format';
import Chart from 'chart.js/auto';
import { Doughnut, Pie } from 'react-chartjs-2';
import ChartDataLables from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
Chart.register(ChartDataLables);
const Report = () => { 
  
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [capturedCosts, setCapturedCosts] = useState([]) ;    
  const [reportData, setReportData] = useState<any>({}) ; 
  const [chartData, setChartData] = useState<any>() ; 
  const [loading, setLoading] = useState(false) ;
  const project_id : any = UrlParams('id') ? UrlParams('id')  : 0;

  const getColor = (index:any) => {
    const color = ['(0,191,255)', '(255,140,0)', '(30,144,255)', '(255,215,0)', '(50,205,50)', '(211,211,211)', '(220,20,60)'];
    let i = index % color.length;
      
    return `rgb${color[i]}`;
}

 

  useEffect( () => { 
    AuthGetData('/project', {id: project_id}).then((response) => {
      if(response.success ){ 
        setProjectInfo(response.data); 
      } 
    })
    AuthGetData('/captured-costs', {id:project_id}).then((response) => {
      if(response.success ){ 
        setCapturedCosts(response.results.data); 
      }  
    }); 
    AuthGetData('/report-data', {id:project_id}).then((response) => {
      if(response.success ){ 
        setReportData(response.results.data); 
      }  
    }); 
    AuthGetData('/chart-data', {id:project_id}).then((response) => {
      if(response.success ){ 
        let _labels:any = [];
        let  _data:any = [];
        let  _backgroundColor:any = [];

        response.results.data.forEach( (trade:any, index:number) => {
          _labels.push(trade.trade_name);
          _data.push(trade.total);
          _backgroundColor.push(getColor(index));
        })

        setChartData({
          labels:_labels,
          datasets: [{
             
            data: _data,
            backgroundColor: _backgroundColor,
            hoverOffset: 4
          }]
        }); 
      }  
    }); 
     

  },[]);
  return ( 
     <div>
      <PageTitle title="Report / Analystic" />
      <div className="row mb-5">

        <div className="mb-2 "><h5>Project: {projectInfo.project_name} - {projectInfo.project_no}</h5></div>

        <div className="col-12 bg-white rounded-lg border p-2">
          <div className="row"> 
            <div className="col-12"> 
              <table className ="table table-bordered table-hover border-0  ">
                <thead>
                <tr>
                  <th>Contract Sum</th>
                  <th>Contract VO</th>
                  <th>Sum + VO</th> 
                  <th>Incl NSC// Total Cost []</th>   
                  <th>Total paid (incl NSC)</th>  
                  <th>O/S</th>  
                  <th>Profit</th> 
                  <th>Margin*</th> 
                </tr>
                </thead>
                <tbody>
                  
                  <tr>
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={reportData.contract_sum} /></td>
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={reportData.contract_vo} /></td>
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={( parseFloat(reportData.contract_sum) + parseFloat(reportData.contract_vo))} /></td> 
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={( parseFloat(reportData.contract_sum) + parseFloat(reportData.contract_vo))} /></td>   
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={reportData.total_paid} /></td> 
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={reportData.os} /></td> 
                    <td>0</td> 
                    <td>0</td> 
                  </tr> 
                 
                </tbody>  
              </table>
            </div>
          </div>



          <h5>Current Captured Costs</h5>
          <div className="row"> 
            <div className="col-12 col-md-6"> 
              <table className ="table table-bordered table-hover border-0  ">
                <thead>
                <tr>
                  <th>Trade</th>
                  <th>Item</th>
                  <th>Sum of Invoice Total</th> 
                  <th>Sum of Outstanding</th>   
                </tr>
                </thead>
                <tbody>
                  {loading ? <tr><td colSpan={8}><Loader /></td></tr> : ( 
                  capturedCosts.map((cost: any, index) => (
                  <tr key={index}>
                    <td>{cost.trade_name}</td>
                    <td>{cost.item_name}</td>
                    <td><NumericFormat thousandSeparator="," decimalSeparator="."  displayType="text" value={cost.total} /></td>
                    <td></td>   
                  </tr> 
                  ))
                  )
                  }
                  
                </tbody>  
              </table>


              


            </div>
            <div className="col-12 col-md-6 text-center"> 
              <div style={{maxWidth:'400px', margin: '0 auto'}}>
              { (chartData != null) ? ( 
                  <Pie data = {chartData} options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        display: false,
                        labels: {
                          color: 'white',
                          font: {
                            size: 16,
                          },
                        },
                      },
                      datalabels: {
                        formatter: (value, context) => { 
                          let sum = 0;
                          context.dataset.data.forEach( (num:any) => {
                            sum += parseFloat( num);
                          })
                          console.log(sum);
                          console.log(context.dataset.data);
                          let percentage = ( (value  * 100) /  sum)
                              .toFixed(1) + '%';
                          return percentage  ;
                        },
                        display: true,
                        color: 'white',
                        font: {
                          weight: 'bold',
                        },
                      },
                    },
                }} />) : ''
              }
              </div>   
            </div>
          </div>
        </div>
         
         
      </div>

       


    </div>

 
  );
}

export default Report;
