import { useEffect, useLayoutEffect, useState } from 'react'; 
import  PageTitle  from '../components/PageTitle'; 
import { AuthGetData, AuthPostData } from '../hook'; 
import dateFormat from 'dateformat';
import { Bounce, ToastContainer, toast } from 'react-toastify';



const Logs = () => { 
  const role = window.localStorage.getItem('role');
  const [logs, setLogs] = useState<any>([]) ;     
  useEffect( () => { 
    AuthGetData('/logs', {}).then((response) => {
      if(response.success ){ 
        setLogs(response.results.data); 
      }  
    }); 
     
  },[]);
  const logAction = (id:Number, action: String) => {
    AuthPostData('/logs/do', {id:id, action: action}).then((response) => {
      if(response.success ){ 
        let _logs = [...logs];
        const index = logs.findIndex((log: any) => log.id == id);
        _logs[index].restored =  ( action == 'undo' ? '1' : '0' );
        setLogs( _logs );
        toast.success(response.results.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }); 
      } 
      else{
        toast.error(response.results.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } 
    }); 
  };
   
  return ( 
     <div>
      <ToastContainer />  
      <PageTitle title="Logs" />
      <div className="row mb-5">
         
        <div className="col-lg-12 col-12 pb-3">
          <div className="log-info">
            { logs.map( (log:any) =>  ( 
            <div className="log-item d-flex justify-content-between" key={log.id}>
              <span>
                <span className="log-name w-auto mr-1 text-bold">{log.name}</span>
                <span className="log-desc flex-1 font-italic">{log.description}</span> 
                <span className="log-time ml-1  "> at {dateFormat(log.created_at, "dd.mm.yyyy HH:mm:ss")}</span>
              </span>
              {role  === '1' &&
              <span className="log-actions">
                <button className={"undo" + (log.restored == '1' ? ' disabled' : '')} onClick={ () => logAction(log.id, 'undo')}><i className="fas fa-undo"></i> undo</button>
                <button className={"redo" + (log.restored != '1' ? ' disabled' : '')} onClick={ () => logAction(log.id, 'redo')}><i className="fas fa-redo"></i> redo</button>
              </span>
              }
            </div>
            ))}
          </div>
        </div>
         
         
      </div>

       


    </div>

 
  );
}

export default Logs;
