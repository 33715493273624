import React, { Component, FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom"; 
import { RecoilRoot } from 'recoil';
import logo from './logo.svg';
import './App.css';
import './components/Header';
import Layout from './components/Layout';
import Home from './pages/Home'; 
import Login from './pages/Login';
import Payments from './pages/Payments';
import Page404 from './pages/Page404';
import Project from './pages/Project';
import Contract from './pages/Contract';
import AddProject from './pages/AddProject';
import EditProject from './pages/EditProject';
import AddContract from './pages/AddContract';
import EditContract from './pages/EditContract';
import Vendors from './pages/Vendors';
import AddVendor from './pages/AddVendor';
import EditVendor from './pages/EditVendor';
import AddAccount from './pages/AddAccount'; 
import EditAccount from './pages/EditAccount';
import Accounts from './pages/Accounts';
import Users from './pages/Users'; 
import Report from './pages/Report'
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import Profile from './pages/Profile';
import Logs from './pages/Logs';
import AdminLogs from './pages/AdminLogs';
import Banks from './pages/Banks';
import AddBank from './pages/AddBank';
import EditBank from './pages/EditBank';
import Trades from './pages/Trades';
import AddTrade from './pages/AddTrade';
import EditTrade from './pages/EditTrade';
import Items from './pages/Items';
import AddItem from './pages/AddItem';
import EditItem from './pages/EditItem';

 

function App() { 
  

  const RequireAuth: FC<{ children: any }> = ({ children }) => {
    let authed = false;
    if (window.localStorage.getItem('token')) {
      authed = true;
    }
    const location = useLocation();
  
    return ( authed ? 
      children
      : <Navigate to="/login" replace state={{ path: location.pathname }}  />);
  }
  
  
  return (
    <RecoilRoot>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<RequireAuth><Home /></RequireAuth>}  />
          <Route path="/login" element={<Login />} />  
            
          <Route path="/dashboard" element={<RequireAuth><Home /></RequireAuth>} />
          <Route path="/dashboard/page/:page" element={<RequireAuth><Home /></RequireAuth>} />
          <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />

          <Route path="/contract" element={<RequireAuth><Contract /></RequireAuth>} />
          <Route path="/add-contract" element={<RequireAuth><AddContract /></RequireAuth>} /> 
          <Route path="/edit-contract" element={<RequireAuth><EditContract /></RequireAuth>} /> 

          <Route path="/project/:id" element={<RequireAuth><Project /></RequireAuth>} /> 
          <Route path="/project/:id/page/:page" element={<RequireAuth><Project /></RequireAuth>} /> 
          <Route path="/add-project" element={<RequireAuth><AddProject /></RequireAuth>} />  
          <Route path="/edit-project" element={<RequireAuth><EditProject /></RequireAuth>} /> 

          <Route path="/vendors/page/:page" element={<RequireAuth><Vendors /></RequireAuth>} />
          <Route path="/vendors" element={<RequireAuth><Vendors /></RequireAuth>} />
          <Route path="/add-vendor" element={<RequireAuth><AddVendor /></RequireAuth>} />   
          <Route path="/edit-vendor" element={<RequireAuth><EditVendor /></RequireAuth>} />

          <Route path="/accounts/page/:page" element={<RequireAuth><Accounts /></RequireAuth>} />
          <Route path="/accounts" element={<RequireAuth><Accounts /></RequireAuth>} />
          <Route path="/add-account" element={<RequireAuth><AddAccount /></RequireAuth>} /> 
          <Route path="/edit-account" element={<RequireAuth><EditAccount /></RequireAuth>} />

          <Route path="/banks/page/:page" element={<RequireAuth><Banks /></RequireAuth>} />
          <Route path="/banks" element={<RequireAuth><Banks /></RequireAuth>} />
          <Route path="/add-bank" element={<RequireAuth><AddBank /></RequireAuth>} /> 
          <Route path="/edit-bank" element={<RequireAuth><EditBank /></RequireAuth>} />

          <Route path="/trades/page/:page" element={<RequireAuth><Trades /></RequireAuth>} />
          <Route path="/trades" element={<RequireAuth><Trades /></RequireAuth>} />
          <Route path="/add-trade" element={<RequireAuth><AddTrade /></RequireAuth>} /> 
          <Route path="/edit-trade" element={<RequireAuth><EditTrade /></RequireAuth>} />

          <Route path="/items/page/:page" element={<RequireAuth><Items /></RequireAuth>} />
          <Route path="/items" element={<RequireAuth><Items /></RequireAuth>} />
          <Route path="/add-item" element={<RequireAuth><AddItem /></RequireAuth>} /> 
          <Route path="/edit-item" element={<RequireAuth><EditItem /></RequireAuth>} />

          <Route path="/users/page/:page" element={<RequireAuth><Users /></RequireAuth>} />
          <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} />
          <Route path="/add-user" element={<RequireAuth><AddUser /></RequireAuth>} /> 
          <Route path="/edit-user" element={<RequireAuth><EditUser /></RequireAuth>} /> 
          <Route path="/report" element={<RequireAuth><Report /></RequireAuth>} /> 
          <Route path="/logs" element={<RequireAuth><Logs /></RequireAuth>} />
          <Route path="/admin-logs" element={<RequireAuth><AdminLogs /></RequireAuth>} />

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
