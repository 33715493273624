import React, { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, PostData, UrlParams } from '../hook';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { Bounce, ToastContainer, toast } from 'react-toastify';
const EditContract = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue , formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [submiting, setSubmiting] = useState(false) ;
  const [error, setError] = useState('') ;
  const [projectOptions, setProjectOptions] = useState([]) ;
  const [vendorOptions, setVendorOptions] = useState([]) ;
  const [selectedProject, setSelectedProject] = useState({'value':'', 'label': ''}) ;
  const [selectedVendor, setSelectedVendor] = useState({'value':'', 'label': ''}) ;
  const [contractNotfound, setContractNotfound] = useState(false) ; 
  const contract_id : any = UrlParams('id') ? UrlParams('id')  : 0;  
  const [projectID, setProjectID] = useState<any>(0) ;
  useEffect( () => { 
    setLoading(true);
    AuthGetData('/contract', {id: contract_id}).then((response) => {
      if(response.success ){ 
        if(response.data){ 
          setValue("id",  response.data.id );
          setValue("amount",  response.data.amount );
          setValue("vendor_id",  response.data.vendor_id );  
          setValue("contract_no",  response.data.contract_no );  
          
          let vendor_id = response.data.vendor_id;
          let vendor_name = response.data.vendor_name; 
          let project_id = response.data.project_id;
          setProjectID(response.data.project_id);
          AuthGetData('/project_options', {}).then((response) => {
            if(response.success ){ 
              setProjectOptions(response.data);
              const pr_selected = response.data.find(( el:any ) => el.value === parseInt( project_id)); 
              if( typeof pr_selected !== 'undefined'){
                setSelectedProject(pr_selected);
                setValue('project_id', project_id);
              } 
            }  
          });  
          AuthGetData('/vendor_options', {}).then((response) => {
            if(response.success ){  
              setVendorOptions(response.data);   
              setSelectedVendor({'value':vendor_id, 'label': vendor_name}); 
               
            }  
             
          });  

 

        }else{
          setContractNotfound(true);
          setError('Contract not found!');
        }
      }  
      setLoading(false);
    })

  },[]);
  
  const  onSubmit = async (FormData: any) => { 
    setSubmiting(true);
    setError('');
    FormData.uid =  window.localStorage.getItem("uid");
    const response = await AuthPostData('/update-contract', FormData) ; 
    if(response.success ){ 
      toast.success('Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSubmiting(false);
    }
    else{
      setSubmiting(false);
      setError(response.message);
    }
     
  };
  if(contractNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger' dangerouslySetInnerHTML={{__html: error}}></div></div> 
    );
  }
  return ( 
     <div>
      <ToastContainer />
      <PageTitle title="Add Contract" />  
      
      <div className="add-project box">
      <h3 className="section-heading">Contract info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label >Project</label> 
            <Controller
              name="project_id"
              control={control}
              rules={{ required: 'Please select a project' }}
               
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select options={projectOptions} onChange={ (e:any) => {setValue('project_id', e?.value); setSelectedProject(e)  } }  onBlur={onBlur} value={selectedProject}  defaultValue={selectedProject} ></Select> 
              )}
            />  
             {errors.project_id && <div className='w-100'><span className="error text-red">{errors.project_id?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label >Contract No.</label>
            <input  className="form-control"   {...register("contract_no", { required: "Contract No. is required" })} type="text" />
            {errors.contract_no && <div className='w-100'><span className="error text-red">{errors.contract_no?.message?.toString()}</span></div>} 
          </div>
           
          <div className="form-group">
            <label >Vendor</label> 
            <Controller
              name="vendor_id"
              control={control}
              rules={{ required: 'Please select a vendor' }}
               
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select options={vendorOptions} onChange={ (e:any) => {setValue('vendor_id', e?.value); setSelectedVendor(e)  } }  onBlur={onBlur} value={selectedVendor}  defaultValue={selectedVendor} ></Select> 
              )}
            />  
             {errors.vendor_id && <div className='w-100'><span className="error text-red">{errors.vendor_id?.message?.toString()}</span></div>} 
          </div>
         
          
            
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={submiting ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Update{submiting ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/project/'+projectID}>Cancel</Link>
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default EditContract;
