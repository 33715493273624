import React, { useEffect, useState } from 'react'; 
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PostData } from '../hook';
const Login = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false) ;
  const [error, setError] = useState('') ;
  useEffect(() => {
    if (window.localStorage.getItem('token')) {
      window.location.replace("/");
    }
    document.body.classList.add('login-page'); 
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
    const response = await PostData('/login', FormData) ;
    
    if(response.success ){
      //localStorage.setItem("token", response.success.token);
      window.localStorage.setItem("token", response.token);
      window.localStorage.setItem("uid", response.id);
      window.localStorage.setItem("role", response.role);
      //document.body.classList.remove('login-page'); 
      //navigate("/");
      window.location.replace("/");
      //window.location.href = "http://www.w3schools.com";
    }
    else{
      setLoading(false);
      setError(response.message);
    }
  };

  return (
    <div className="d-flex justify-content-center"> 
      <div className="login-box"> 
        <div className="card card-outline card-primary login-box-round p-3">
          <div className=" text-center mt-4">
            <img src={process.env.PUBLIC_URL + 'dist/img/logo.png'} />
          </div>
          <div className="card-body">
            <h3 className='text-center'>Login to Account</h3>
            <p className="login-box-msg">Please enter your email and password to continue</p>
            <form onSubmit={handleSubmit(onSubmit)}>
               
              <div className="input-group mb-3">
                <input  className="form-control" placeholder="Email" {...register("email", { required: "Email Address is required",   pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format"
                  } })} type="email" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
                {errors.email && <div className='w-100'><span className="error text-red">{errors.email?.message?.toString()}</span></div>} 
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" {...register("password", { required: "Password is required" })} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
                {errors.password && <div className='w-100'><span className="error text-red">{errors.password?.message?.toString()}</span></div>}
              </div>
              
              
              <div className="row">  
                <div className="col">
                  <button type="submit" className={loading ? 'btn btn-primary btn-block d-flex align-items-center justify-content-center disabled ' : 'btn btn-primary btn-block'}>Sign In {loading ? <span className="loader ml-2 "></span> : '' }</button>
                </div>
                 
              </div> 
              {error !== '' ? <div className="mt-3 alert alert-danger">{error}</div> : ''} 

            </form>
            
             
          </div> 
        </div> 
      </div>
    </div>

 
  );
}

export default Login;
