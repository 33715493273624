import { useEffect, useState } from 'react'; 
import { AuthGetData, AuthPostData } from '../hook';
import { Link } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { pageTitleState, userInfoState } from '../state'; 

 
function Header() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [willClickResult, setWillClickResult] = useState(false); 
  const userInfo:any = useRecoilValue(userInfoState);
  
 
  useEffect(() => {
    const timer = setTimeout(() => {
      if(searchKeyword.trim() != ''){
        setSearching(true);
        AuthGetData('/project-search', {keyword: searchKeyword}).then((response) => {
          if(response.success ){ 
            setSearchResults(response.data); 
          }  
          setSearching(false);
        });  
      }
      else{
        setSearchResults([]); 
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [searchKeyword]);
  
  const closeSearchResults = () => {
    if(!willClickResult){
      setOpenSearch(false);
    }  
  }
  const logout = () => {
    AuthPostData('/logout', {}).then((response) => {
      if(response.success ){ 
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("token"); 
        window.location.replace("/login");
      }   
    }); 
  }
  
  return (
      
<nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>
    <li className="nav-item"> 
        <form className="header-search-form" onBlur={closeSearchResults}>
          <div className="input-group input-group-sm">
            
            <div className="input-group-append">
              <button className="btn btn-navbar header-search-btn" type="submit"> 
                <i className="fas fa-search" />
              </button>
               
            </div>
            <input className="form-control form-control-navbar search-keyword" type="search" placeholder="Search Project" onChange={e => setSearchKeyword(e.target.value)}  onFocus={()=>setOpenSearch(true)}  />
            <div className={"search-results "+ ( openSearch ? "d-block" : "d-none" )} onMouseOver={() => setWillClickResult(true)} onMouseOut={() => setWillClickResult(false)}>
              <ul className="list-group">
              {searching ?  <li className="list-group-item">Searching...</li> : ( 
              searchResults.map((project: any, index) => (
              <>
                <li className="list-group-item p-0"><Link to={"/project/"+project.id} className="d-block p-2">{project.project_name}</Link></li> 
                
              </>
              )))}
              </ul>
            </div>
          </div>
        </form>
      
    </li>
     
  </ul>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto ">
     
      
    {/* Notifications Dropdown Menu */}
    <li className="nav-item dropdown">
      <a className="d-flex align-items-center" data-toggle="dropdown" href="#">
        <div className="avatar d-inline-block rounded-circle bg-light mr-2" style={{width: '50px', height: '50px'}}></div>
        <span className="username mr-2 text-dark">{userInfo?.name}</span>
        <i className="fa fa-angle-down text-dark" /> 
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">  
        <Link className="dropdown-item" to={'/profile'}> 
          <i className="far fa-user mr-2" /> Profile 
        </Link>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item" onClick={logout}> 
          <i className="fas fa-sign-out-alt mr-2" /> Logout 
        </a>
         
         
      </div>
    </li>
     
  </ul>
</nav> 

 
  );
}

export default Header;
