import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pageTitleState   } from './state';
import { useLocation } from 'react-router-dom';

 
 
 
export  function UpdatePageTitle( title: string){
  const setPageTitle = useSetRecoilState(pageTitleState);	
  setPageTitle(title);
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export  function UrlParams( param_name: string){
  let query = useQuery();
  return query.get(param_name);
}
export  function GetData( param_name: string){
  const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
  let query = useQuery();
  return query.get(param_name);
}
export async function AuthPostData(path: string, params: {}) {
   
  const url = process.env.REACT_APP_API_ENDPOINT + path;
  
  const accessToken = window.localStorage.getItem("token");
  try{
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+accessToken, 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }); 
    if(response?.ok){
      const data = await response.json();
      return data;
    }
    else{
      if(response?.status == 401){
        window.localStorage.removeItem("token");
        
      }
      throw Error("API Error: "+ response?.status);
    }
  } catch(error:any)  {
    return {success: false, message: error?.message};
  }
  return {success: false, message: 'Error, Please try again!'};
}

export async function AuthGetData(path: string, params: any) {
  const par = Object.keys(params).map(
    (key) =>
      `${key}=${
        typeof params[key] === "object"
          ? JSON.stringify(params[key])
          : params[key]
      }`
  ).join("&");
  const url = process.env.REACT_APP_API_ENDPOINT + path+'?'+par;
  const accessToken = localStorage.getItem('token');
  //const accessToken = window.sessionStorage.getItem("token");
  try{
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+accessToken, 
        'Content-Type': 'application/json'
      }, 
    }); 
    if(response?.ok){
      const data = await response.json();
      return data;
    }
    else{
      if(response?.status == 401){
        window.localStorage.removeItem("token"); 
      }
      throw Error("API Error: "+ response?.status);
    }
  } catch(error:any)  {
    return {success: false, message: error?.message};
  }
  return {success: false, message: 'Error, Please try again!'};
}
export async function PostData(path: string, params: {}) {
  const url = process.env.REACT_APP_API_ENDPOINT + path;
   
  try{
    const response = await fetch(url, {
      method: 'POST', 
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }); 
    if(response?.ok){
      const data = await response.json();
      return data;
    }
    else{
      throw Error("API Error: "+ response?.status);
    }
  } catch(err)  {
    return {success: false, message: 'Error, Please try again!'};
  }
  return {success: false, message: 'Error, Please try again!'};
}