import { atom, selector } from 'recoil';
import { AuthGetData } from './hook';

 
export const pageTitleState = atom({
  key: 'pageTitle',
  default: "Dashboard",
});
 
export const currentMenuState = atom({
  key: 'currentMenu',
  default: "dashboard",
});
 
export const userInfoState = selector({
  key: "userInfo",
 
  get: async ({get}) => {
      
    const response =  await  AuthGetData('/profile', {});
    if(response.success ){  
      return response.data ;
    }  
    else{
      return null
    }
     
     
    
  }
});