import React, { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, PostData, UrlParams } from '../hook';  
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
const EditUser = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue ,watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [submiting, setSubmiting] = useState(false) ;
  const [userNotfound, setUserNotfound] = useState(false) ;
  const [error, setError] = useState('') ;   
  const [roles, setRoles] = useState([]) ;
  const [selectedRole, setSelectedRole] = useState({'value':'', 'label': ''}) ;
  const user_id : any = UrlParams('id') ? UrlParams('id')  : 0;
  
  useEffect( () => { 
    setLoading(true);
    AuthGetData('/roles', {}).then((response) => { 
      if(response.success ){ 
        setRoles(response.data); 
      }  
    }); 
    AuthGetData('/user', {id: user_id}).then((response) => {
      if(response.success ){ 
        if(response.data){
          setSelectedRole({'value': response.data.role, 'label': response.data.role_name})
          setValue("name",  response.data.name );
          setValue("email",  response.data.email );
          setValue("id",  response.data.id );
          setValue("role",  response.data.role );
        }else{
          setUserNotfound(true);
          setError('User not found!');
        }
      } 
      setLoading(false);
      
    })
   
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setSubmiting(true);
    setError('');
   
    const response = await AuthPostData('/update-user', FormData) ; 
    if(response.success ){ 
      toast.success('Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSubmiting(false);
    }
    else{
      setSubmiting(false);
      setError(response.message);
    }
     
  };
  if(userNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger' dangerouslySetInnerHTML={{__html: error}}></div></div> 
    );
  }
  return ( 
     <div>
      <ToastContainer />  
      <PageTitle title="Edit User" />  
      
      <div className={"edit-user box" + ( loading ? " loading" : "" )}>
      <h3 className="section-heading">User info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Name</label>
            <input  className="form-control"   {...register("name", { required: "Name is required" })} type="text"  />
            {errors.name && <div className='w-100'><span className="error text-red">{errors.name?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Email</label>
            <input  className="form-control"   {...register("email", { required: "email is required", pattern: { value: /\S+@\S+\.\S+/, message: "Entered value does not match email format" } })}  />
            {errors.email && <div className='w-100'><span className="error text-red">{errors.email?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label >Role</label> 
            <Controller
              name="role"
              control={control}
              rules={{ required: 'Please select role' }}
               
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select options={roles} onChange={ (e:any) => {setValue('role', e?.value); setSelectedRole(e)  } }  onBlur={onBlur} value={selectedRole}  defaultValue={selectedRole} ></Select> 
              )}
            />  
             {errors.role && <div className='w-100'><span className="error text-red">{errors.role?.message?.toString()}</span></div>} 
          </div> 
          <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" {...register('password', {
                required: false,
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters"
                }
              })} 
            />
            {errors.password && <div className='w-100'><span className="error text-red">{errors.password?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input type="password" className="form-control" {...register('c_password', {
                required: false,
                validate: (val: string) => {
                  if (watch('password') != val) {
                    return "Your passwords do no match";
                  }
                },
              })}
            />
            {errors.c_password && <div className='w-100'><span className="error text-red">{errors.c_password?.message?.toString()}</span></div>} 
          </div>
            
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={submiting ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Update{submiting ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/users'}>Cancel</Link>
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default EditUser;
