import React, { useEffect, useState } from 'react';  
import { Link, useNavigate, useParams } from 'react-router-dom'; 
import { AuthGetData, AuthPostData } from '../hook';
import Loader from './Loader';
import dateFormat from "dateformat"; 
import ReactPaginate from 'react-paginate';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { Project } from '../types';

const  ProjectsList = () => {
  const [projects, setProjects] = useState<Project[]>([]) ;
  const [loading, setLoading] = useState(false) ;
  const [processing, setProcessing] = useState(false) ; 
  const navigate = useNavigate();
  let { page } = useParams();
  let cPage = page ? parseInt(page) : 1;
  const [paginationInfo, setPaginationInfo] = useState({pageCount: 1,})
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/projects', {page:cPage}).then((response) => {
      if(response.success ){ 
        setProjects(response.results.data);
        setPaginationInfo({pageCount: response.results.last_page == 1 ? 0 :  response.results.last_page});
      } 
      else{
         
      }
      setLoading(false);
    });  
     
  },[cPage]);
  
  const handlePageClick = (event:any) => {
    let selected_page = event.selected + 1;
    navigate('/dashboard/page/'+selected_page);  
  };
 
  const deleteProjects = (ids:Number[], project_names:String[]) => {
    const confirmBox = window.confirm(
      `Do you really want to delete Project(s): ${project_names.join(',')}?`
    )
    if (confirmBox === true) {
      setProcessing(true);
      AuthPostData('/delete-projects', {ids:ids}).then((response) => {
        console.log(response);
        if(response.success ){ 
          if(response.results.deleted.length > 0){
            toast.success('Deleted Project ID(s): '+response.results.deleted.join(','), {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            setProjects(
              projects.filter(account =>  !response.results.deleted.includes(account.id) )
            );
          }
          if(response.results.error.length > 0){
            toast.error('Can not delete Project ID(s): '+response.results.error.join(','), {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } 
        else{
          toast.error(response.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        
        setProcessing(false);
      });  
    }
  };
  return ( 
    <>
    <ToastContainer />
    <div className={"projects-list list-data" + (processing ? " loading" : "")}>
      <h3 className="section-heading">Projects</h3>
        
      <div className="table-container">
        <table className ="table table-bordered table-hover border-0 data-tbl">
          <thead>
          <tr>
            <th style={{width:'70px'}}>ID</th>
            <th>Project Number</th>
            <th>Project Name</th>
            <th>Date Created</th>
            <th>Last Modified</th>
            <th>Contracts</th>
            <th>Status</th> 
            <th className="action-col"></th>
              
          </tr>
          </thead>
          <tbody>
            {loading ? <tr><td colSpan={6}><Loader /></td></tr> : ( 
            projects.map((project: any, index) => (
            <tr key={index}>
              <td>{project.id}</td>
              <td>{project.project_no}</td>
              <td>{project.project_name}</td>
              <td>{dateFormat(project.created_at, "dd.mm.yyyy - h:MM TT") }</td>
              <td>{dateFormat(project.updated_at, "dd.mm.yyyy - h:MM TT") }</td>
              <td>{project.contract_count}</td>
              <td><span className={project.status == 'Completed' ? "status bg-green" : "status bg-red"}>{project.status}</span></td> 
              <td style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                <Link to={"/project/"+project.id+""} className="action-btn">Enter Project</Link> 
                <Link to={"/report?id="+project.id} className="action-btn">Analystics</Link>   
                <Link to={"/edit-project?id="+project.id} className="action-btn w-auto" style={{minWidth:'1px'}}>Edit</Link> 
                <button className="action-btn w-auto bg-red" style={{minWidth:'1px'}} onClick={ () => deleteProjects([project.id],[project.project_name])}>Delete</button> 
              </td>
            </tr> 
            ))
            )
            }
            
          
          </tbody>
          
        </table>
      </div>
      <div className="pagi text-center ">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick} 
          initialPage={cPage-1} 
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={paginationInfo.pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName='page-item'
          className="pagination pagination-sm d-inline-flex"
          pageLinkClassName="page-link"
          previousClassName="page-link"
          nextClassName="page-link"
        />     
      </div>
    </div>
    </>
  );
       
}
export default ProjectsList