import { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import {  AuthPostData } from '../hook'; 
import { NumericFormat } from 'react-number-format';
const AddBank = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control , formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [error, setError] = useState('') ;  
  useEffect( () => { 
    
   
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setLoading(true);
    setError('');
    
    const response = await AuthPostData('/add-bank', FormData) ; 
    if(response.success ){ 
      navigate('/banks'); 
    }
    else{
      setLoading(false);
      setError(response.message);
    }
     
  };
  return ( 
     <div>
      <PageTitle title="Add Acount" />  
      
      <div className="add-project box">
      <h3 className="section-heading">Account info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
           
          <div className="form-group">
            <label>Account Name</label>
            <input  className="form-control"   {...register("bank_name", { required: "Bank Name is required" })} type="text" />
            {errors.bank_name && <div className='w-100'><span className="error text-red">{errors.bank_name?.message?.toString()}</span></div>} 
          </div>
          
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={loading ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Add{loading ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/banks'}>Cancel</Link>
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default AddBank;
