import { useEffect, useState } from 'react'; 
import { Controller, useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, UrlParams } from '../hook'; 
import { NumericFormat } from 'react-number-format'; 
import { Bounce, ToastContainer, toast } from 'react-toastify';
const EditBank = () => {
  
  const { register, handleSubmit, control, setValue , formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ; 
  const [submiting, setSubmiting] = useState(false) ;
  const [error, setError] = useState('') ; 
  const [bankNotfound, setBankNotfound] = useState(false) ;
   
  const bank_id : any = UrlParams('id') ? UrlParams('id')  : 0;
  useEffect( () => { 
    setLoading(true);
    AuthGetData('/bank', {id: bank_id}).then((response) => {
      if(response.success ){ 
        if(response.data){ 
          setValue("bank_name",  response.data.bank_name ); 
          setValue("id",  response.data.id );
        }else{
          setBankNotfound(true);
          setError('Bank not found!');
        }
      } 
      setLoading(false);
    })
   
  },[]);
  
  const  onSubmit = async (FormData: any) => {
    setSubmiting(true);
    setError('');
    
    const response = await AuthPostData('/update-bank', FormData) ; 
    if(response.success ){ 
      toast.success('Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSubmiting(false);
    }
    else{
      setSubmiting(false);
      setError(response.message);
    }
     
  };
  if(bankNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger' dangerouslySetInnerHTML={{__html: error}}></div></div> 
    );
  }
  return ( 
     <div>
      <ToastContainer />
      <PageTitle title="Edit Bank" />  
      
      <div className={"edit-bank box" + ( loading ? " loading" : "" )}>
      <h3 className="section-heading">Bank info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
           
          
          <div className="form-group">
            <label>Bank Name</label>
            <input  className="form-control"   {...register("bank_name", { required: "Bank Name is required" })} type="text"  />
            {errors.bank_name && <div className='w-100'><span className="error text-red">{errors.bank_name?.message?.toString()}</span></div>} 
          </div>
          
            
          {error !== '' ? <div className="my-2 alert alert-danger" dangerouslySetInnerHTML={{__html: error}}></div> : ''} 
          <button type="submit" className={submiting ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Update{submiting ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/banks'}>Cancel</Link> 
        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default EditBank;
