import { useEffect, useState } from 'react';  
import {AuthGetData, AuthPostData} from '../hook';
import { Link, useParams,useNavigate } from 'react-router-dom';
import PageTitle from '../components/PageTitle'; 
import Loader from '../components/Loader'; 
import ReactPaginate from 'react-paginate';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { join } from 'path';
import { User } from '../types';
const Users = () => {
  const [users, setUsers] = useState<User[]>([]) ;
  const [loading, setLoading] = useState(false) ;  
  const [processing, setProcessing] = useState(false) ; 
  const navigate = useNavigate();
  let { page } = useParams();
  let cPage = page ? parseInt(page) : 1;
  const [error, setError] = useState('') ;   
  const [paginationInfo, setPaginationInfo] = useState({pageCount: 1,})
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/users', {page:cPage}).then((response) => {
      if(response.success ){ 
        setUsers(response.results.data);
        setPaginationInfo({pageCount: response.results.last_page == 1 ? 0 :  response.results.last_page});
      } 
      else{
        setError(response.results.message);
      }
      setLoading(false);
    });  
     
  },[cPage]);
  const handlePageClick = (event:any) => {
    let selected_page = event.selected + 1;
    navigate('/users/page/'+selected_page);  
  };
  const deleteUsers = (uid:Number[]) => {
    const confirmBox = window.confirm(
      `Do you really want to delete user ID(s): ${uid.join(',')}?`
    )
    if (confirmBox === true) {
      setProcessing(true);
      AuthPostData('/delete-users', {ids:uid}).then((response) => {
        if(response.success ){ 
          if(response.results.deleted.length > 0){
            toast.success('Deleted User ID(s): '+response.results.deleted.join(','), {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }); 
            setUsers(
              users.filter(user =>  !response.results.deleted.includes(user.id) )
            );
          }
          if(response.results.error.length > 0){
            toast.error('Can not delete User ID(s): '+response.results.error.join(','), {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } 
        else{
          toast.error(response.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        
        setProcessing(false);
      });  
    }
  };
  if(error){
    return (
      <div className="pt-3"><div className='alert alert-danger' dangerouslySetInnerHTML={{__html: error}}></div></div> 
    );
  }
  return ( 
     <div>
      <ToastContainer />  
      <PageTitle title="Users" />   
      <div className="py-2 mb-3"><Link className="btn btn-primary" to={'/add-user'}>Add User</Link></div> 
      <div className={"users-list list-data" + (processing ? " loading" : "")}> 
      <div className="table-container">
        <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th> 
                <th>Role</th>
                <th className="action-col">Action</th>
              </tr>
              </thead>
            <tbody>
              { (users.length < 1 && !loading ) ? <tr><td colSpan={4}>No User!</td></tr> : ( 
                loading ? <tr><td colSpan={4}><Loader /></td></tr> : ( 
                  users.map((user: any, index) => (
                <tr key={index}>
                
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role_name}</td> 
                  <td>
                    <Link to={"/edit-user?id=" + user.id} className="action-btn w-auto " style={{minWidth:'1px'}}>Edit</Link> 
                    <button className="action-btn w-auto bg-red" style={{minWidth:'1px'}} onClick={ () => deleteUsers([user.id])}>Delete</button>
                  </td>
                </tr>
                )))
              )
            }
            </tbody>
            
          </table>
        </div>  
        <div className="pagi text-center ">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick} 
            disableInitialCallback = {true}
            initialPage={cPage-1} 
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={paginationInfo.pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName='page-item'
            className="pagination pagination-sm d-inline-flex"
            pageLinkClassName="page-link"
            previousClassName="page-link"
            nextClassName="page-link"
          />     
        </div>
      </div>
      
        
    </div>

 
  );
}

export default Users;
