import React, { useEffect, useState } from 'react'; 
import { useForm } from "react-hook-form";
import PageTitle from '../components/PageTitle'; 
import { Link, useNavigate } from 'react-router-dom';
import { AuthGetData, AuthPostData, PostData, UrlParams } from '../hook';
import { Bounce, ToastContainer, toast } from 'react-toastify';
const EditProject = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue,getValues, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false) ;
  const [submiting, setSubmiting] = useState(false) ;
  const [error, setError] = useState('') ;
  const [projectNotfound, setProjectNotfound] = useState(false) ;
  const project_id : any = UrlParams('id') ? UrlParams('id')  : 0;
  useEffect( () => {  
    setLoading(true);
    AuthGetData('/project', {id: project_id}).then((response) => {
      if(response.success ){ 
        if(response.data){ 
          setValue("id",  response.data.id );
          setValue("project_no",  response.data.project_no );
          setValue("project_name",  response.data.project_name ); 
          setValue("company",  response.data.company ); 
          setValue("remark",  response.data.remarks ); 
        }else{
          setProjectNotfound(true);
          setError('Project not found!');
        }
      }  
      setLoading(false);
    })
   
  },[]);
  const  onSubmit = async (FormData: any) => {
    setSubmiting(true);
    setError('');
    const response = await AuthPostData('/update-project', FormData) ; 
    if(response.success ){ 
      toast.success('Updated', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSubmiting(false);
    }
    else{
      setSubmiting(false);
      setError(response.message);
    }
     
  };
  if(projectNotfound){
    return (
      <div className="pt-3"><div className='alert alert-danger' dangerouslySetInnerHTML={{__html: error}}></div></div> 
    );
  }
  return (  
     <div>
      <ToastContainer />
      <PageTitle title="Edit project" />  
      
      <div className={"edit-project box" + ( loading ? " loading" : "" )}>
      <h3 className="section-heading">Project info</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label >Project No.</label>
            <input  className="form-control"   {...register("project_no", { required: "Project No. is required" })} type="text" />
            {errors.project_no && <div className='w-100'><span className="error text-red">{errors.project_no?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Project Name</label>
            <input  className="form-control"   {...register("project_name", { required: "Project Name is required" })} type="text" />
            {errors.project_name && <div className='w-100'><span className="error text-red">{errors.project_name?.message?.toString()}</span></div>} 
          </div>
          <div className="form-group">
            <label>Company</label>
            <select {...register("company", { required: true })} className="form-control" defaultValue={getValues("company")}   onChange={ (e) => { setValue("company",  e.target.value ); } }>
              <option value="Laputa Engineering">Laputa Engineering</option>
              <option value="PECO Engineering">PECO Engineering</option>
            </select>
          </div>
          <div className="form-group">
            <label>Remark</label>
            <textarea  className="form-control"   {...register("remark", { required: false })}></textarea>
            {errors.remark && <div className='w-100'><span className="error text-red">{errors.remark?.message?.toString()}</span></div>} 
          </div>
          {error !== '' ? <div className="my-2 alert alert-danger">{error}</div> : ''} 
          <button type="submit" className={submiting ? 'btn btn-primary  d-inline-flex align-items-center justify-content-center disabled ' : 'btn btn-primary  '}>Update{submiting ? <span className="loader ml-2 "></span> : '' }</button>
          <Link className="btn btn-danger ml-2" to={'/'}>Cancel</Link>


        </form>
       
      </div>
       
        
    </div>

 
  );
}

export default EditProject;
