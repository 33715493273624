import React, { useEffect, useState } from 'react';  
import  PageTitle  from '../components/PageTitle';
import { Link, useNavigate, useParams } from 'react-router-dom'; 
import { AuthGetData, AuthPostData } from '../hook'; 
import Loader from '../components/Loader';
import ReactPaginate from 'react-paginate';
import { Vendor } from '../types';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const Vendors = () => { 
  const [vendors, setVendors] = useState<Vendor[]>([]) ;
  const [loading, setLoading] = useState(false) ;
  const [processing, setProcessing] = useState(false) ; 
  const navigate = useNavigate();
  let { page } = useParams();
  let cPage = page ? parseInt(page) : 1;
  const [paginationInfo, setPaginationInfo] = useState({pageCount: 1,})
  useEffect( () => {
    setLoading(true); 
    AuthGetData('/vendors', {}).then((response) => {
    console.log(response);
      if(response.success ){ 
        setVendors(response.results.data);
        setPaginationInfo({pageCount: response.results.last_page === 1 ? 0 :  response.results.last_page});
      } 
      else{
         
      }
      setLoading(false);
    });  
     
  },[cPage]);
  const handlePageClick = (event:any) => {
    let selected_page = event.selected + 1;
    navigate('/vendors/page/'+selected_page);  
  };
  const deleteVendors = (ids:Number[]) => {
    const confirmBox = window.confirm(
      `Do you really want to delete Vendors(s): ${ids.join(',')}?`
    )
    if (confirmBox === true) {
      setProcessing(true);
      AuthPostData('/delete-vendors', {ids:ids}).then((response) => {
        console.log(response);
        if(response.success ){ 
          if(response.results.deleted.length > 0){
            toast.success('Deleted Vendor ID(s): '+response.results.deleted.join(','), {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            setVendors(
              vendors.filter(vendor =>  !response.results.deleted.includes(vendor.id) )
            );
          }
          if(response.results.error.length > 0){
            toast.error('Can not delete Vendor ID(s): '+response.results.error.join(','), {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } 
        else{
          toast.error(response.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        
        setProcessing(false);
      });  
    }
  };
  return ( 
     <div>
      <PageTitle title="Vendors" />
      <ToastContainer />  

      <div className="py-2 mb-3"><Link to="/add-vendor" className="btn btn-primary">Add Vendor</Link> </div>

      <div className={"vendors-list list-data" + (processing ? " loading" : "")}> 
        <div className="table-container">
          <table className ="table table-bordered table-hover border-0 data-tbl">
            <thead>
            <tr>
              <th>ID</th>
              <th>Vendor Name</th>
              <th>Contact No</th> 
              <th>Account No</th>
              <th>Phone</th>
              <th>Fax</th> 
              <th>Email</th> 
              <th className="action-col"></th>
                
            </tr>
            </thead>
            <tbody>
              {loading ? <tr><td colSpan={8}><Loader /></td></tr> : ( 
              vendors.map((vendor: any, index) => (
              <tr key={index}>
                <td>{vendor.id}</td>
                <td>{vendor.vendor_name}</td>
                <td>{vendor.contact_no}</td>
                <td>{vendor.account_no}</td>
                <td>{vendor.phone}</td>
                <td>{vendor.fax}</td> 
                <td>{vendor.email}</td>
                
                <td>
                  { vendor.id !== 1  ?  
                  <>
                  <Link to={"/edit-vendor?id="+vendor.id} className="action-btn w-auto" style={{minWidth:'1px'}}>Edit</Link>  
                  <button className="action-btn w-auto bg-red" style={{minWidth:'1px'}} onClick={ () => deleteVendors([vendor.id])}>Delete</button>
                  </>
                  : ''  }
                </td>
              </tr> 
              ))
              )
              }
              
            
            </tbody>
            
          </table>
        </div>
        <div className="pagi text-center ">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick} 
            initialPage={cPage-1} 
            disableInitialCallback = {true}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={paginationInfo.pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName='page-item'
            className="pagination pagination-sm d-inline-flex"
            pageLinkClassName="page-link"
            previousClassName="page-link"
            nextClassName="page-link"
          />     
        </div>
      </div>


    </div>

 
  );
}

export default Vendors;
